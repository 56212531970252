@import "~@sas-te/alfabeto-tokens/src/scss/mixins"
@import "~@sas-te/alfabeto-tokens/src/scss/functions"
@import "~@sas-te/alfabeto-tokens/src/scss/variables"
@import "~@/sass/abstracts/mixins"











































































.tour-finished__modal
  .tv-modal
    +mq-l
      box-sizing: border-box
      +flex_center

  &__body
    text-align: center
    padding-bottom: 10px

  &__info
    padding-top: 20px

    h3
      font-size: 28px
      margin-bottom: 10px

    p
      color: $color-ink-light
      font-size: 16px
      margin-bottom: 20px
